<ng-container *ngIf="paginator.total > 0;else showMessage ">

  <!-- start: Paginator -->


  <div class="d-flex flex-wrap py-2 mr-3">
    <ng-pagination
      [collectionSize]="paginator.total"
      [(page)]="paginator.page"
      [maxSize]="4"
      [rotate]="true"
      [boundaryLinks]="true"
      [pageSize]="paginator.pageSize"
      (pageChange)="pageChange($event)"
    ></ng-pagination>
  </div>
  <div class="d-flex align-items-center py-3">
    <!-- begin: loading -->
    <div class="d-flex align-items-center" *ngIf="isLoading">
      <div class="mr-2 text-muted">Loading...</div>
      <div class="spinner spinner-primary mr-10"></div>
    </div>
    <!-- end: loading -->

    <!-- begin: pageSize -->
    <select
      class="form-control form-control-sm font-weight-bold mr-4 border-0 bg-light false"
      style="width: 75px"
      [(ngModel)]="paginator.pageSize"
      (change)="sizeChange()"
    >
      <option class="btn" *ngFor="let ps of pageSizes">
        {{ ps }}
      </option>
      </select>
      <span class="react-bootstrap-table-pagin ation-total">
        Mostrando registros {{ pageInit }} - {{ (paginator.pageSize < paginator.total)  ? pageEnd : paginator.total }} de {{ paginator.total }}
      </span>
    <!-- end: pageSize -->
  </div>
  <!-- end: Paginator -->
</ng-container>
<ng-template #showMessage >
  <span class="w-100 text-center">Nenhum registro encontrado!</span>
</ng-template>