import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserModel } from '../../_models/user.model';
import { environment } from '../../../../../environments/environment';

const API_USERS_URL = `${environment.apiUrl}`;

@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService {
  constructor(private http: HttpClient) { }

  // public methods
  login(username: string, password: string): Observable<any> {
    return this.http.post(`${API_USERS_URL}/login`, { username, password });
  }

  // CREATE =>  POST: add a new user to the server
  createUser(user: UserModel): Observable<UserModel> {
    return this.http.post<UserModel>(API_USERS_URL, user);
  }

  // Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false
  forgotPassword(username: string): Observable<boolean> {
    return this.http.post<boolean>(`${API_USERS_URL}/forgot-password`, {
      username,
    });
  }

  // Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false
  resetPassword(token, id, password): Observable<boolean> {
    return this.http.post<boolean>(`${API_USERS_URL}/reset-password/${token}`, {
      id,
      password
    });
  }

  getUserByUsername(username): Observable<any> {
    return this.http.get<UserModel>(`${API_USERS_URL}/users/${username}`);
  }

  getUserPicture(id): Observable<any> {
    return this.http.get<any>(`${API_USERS_URL}/users/${id}/avatar`);
  }

}
