// tslint:disable:variable-name
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { PaginatorState } from '../models/paginator.model';
import { ITableState, TableResponseModel } from '../models/table.model';
import { BaseModel } from '../models/base.model';
import { SortState } from '../models/sort.model';
import { GroupingState } from '../models/grouping.model';
import { environment } from '../../../../../environments/environment';
import { searchInArray } from 'src/app/_fake/fake-helpers/http-extenstions';

const DEFAULT_STATE: ITableState = {
  filter: {},
  paginator: new PaginatorState(),
  sorting: new SortState(),
  searchTerm: '',
  grouping: new GroupingState(),
  entityId: undefined
};

export abstract class TableService<T> {
  // Private fields
  protected baseForSearchTerm = new BehaviorSubject<T[]>([]);
  protected _items$ = new BehaviorSubject<T[]>([]);
  protected _isLoading$ = new BehaviorSubject<boolean>(false);
  protected _isFirstLoading$ = new BehaviorSubject<boolean>(true);
  protected _tableState$ = new BehaviorSubject<ITableState>(DEFAULT_STATE);
  protected _errorMessage = new BehaviorSubject<string>('');
  protected _subscriptions: Subscription[] = [];
  protected _params$ = new BehaviorSubject<any>({});


  // Getters
  get items$() {
    return this._items$.asObservable();
  }
  get isLoading$() {
    return this._isLoading$.asObservable();
  }
  get isFirstLoading$() {
    return this._isFirstLoading$.asObservable();
  }
  get errorMessage$() {
    return this._errorMessage.asObservable();
  }
  get subscriptions() {
    return this._subscriptions;
  }
  // State getters
  get paginator() {
    return this._tableState$.value.paginator;
  }
  get filter() {
    return this._tableState$.value.filter;
  }
  get sorting() {
    return this._tableState$.value.sorting;
  }
  get searchTerm() {
    return this._tableState$.value.searchTerm;
  }
  get grouping() {
    return this._tableState$.value.grouping;
  }

  protected http: HttpClient;
  // API URL has to be overrided
  API_URL = `${environment.apiUrl}/endpoint`;
  constructor(http: HttpClient) {
    this.http = http;
  }

  // CREATE
  // server should return the object with ID
  create(item) {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    return this.http.post<BaseModel>(this.API_URL, item).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        return of({ id: 1 });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  
  findParams({ params }): Observable<any> {
    return this.http.get<BaseModel>(this.API_URL, { params }).pipe(
      map((response: any) => {
        return response?.data || [];
      }),
      catchError((err) => {
        return of(undefined);
      })
    )
  }

  find(params): Observable<TableResponseModel<T>> {
    return this.http.get<TableResponseModel<T>[]>(this.API_URL, {params})
    .pipe(
      map(({ data, meta }: any) => {        
        return {
          items: data || [] ,
          total: meta?.page?.elements || 0
        };
      })
    );
  }

  getItemById(id: number): Observable<BaseModel> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = `${this.API_URL}/${id}`;
    return this.http.get<BaseModel>(url).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('GET ITEM BY IT', id, err);
        return of({ id: undefined });
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  // UPDATE
  update(item: BaseModel): Observable<any> {
    const url = `${this.API_URL}/${item.id}`;
    this._isLoading$.next(true);
    this._errorMessage.next('');
    return this.http.put(url, item).pipe(
      finalize(() => this._isLoading$.next(false))
    );
  }

  // UPDATE Status
  updateStatusForItems(ids: number[], status: number): Observable<any> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const body = { ids, status };
    const url = this.API_URL + '/updateStatus';
    return this.http.put(url, body).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('UPDATE STATUS FOR SELECTED ITEMS', ids, status, err);
        return of([]);
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  filterByDescription(searchTerm: string) {
    const resultSearch = searchInArray(this.baseForSearchTerm?.value || [], searchTerm)

    this._items$.next(resultSearch);
    this.patchStateWithoutFetch({
      paginator: this._tableState$.value.paginator.recalculatePaginator(
        resultSearch.length
      ),
    });
  }

  // DELETE
  delete(id: any): Observable<any> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = `${this.API_URL}/${id}`;
    return this.http.delete(url).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('DELETE ITEM', id, err);
        return of({});
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  // delete list of items
  deleteItems(ids: number[] = []): Observable<any> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const url = this.API_URL + '/deleteItems';
    const body = { ids };
    return this.http.put(url, body).pipe(
      catchError(err => {
        this._errorMessage.next(err);
        console.error('DELETE SELECTED ITEMS', ids, err);
        return of([]);
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  public fetch() {
    this._isLoading$.next(true);
    this._errorMessage.next('');

    const request = this.find(this._params$.value)
      .pipe(
        tap((res: TableResponseModel<T>) => {
          this.baseForSearchTerm.next(res.items);
          this._items$.next(res.items);
          this.patchStateWithoutFetch({
            paginator: this._tableState$.value.paginator.recalculatePaginator(
              res.total
            ),
          });
        }),
        catchError((err) => {
          this._errorMessage.next(err);
          this.baseForSearchTerm.next([]);
          this._items$.next([]);
          this.patchStateWithoutFetch({
            paginator: this._tableState$.value.paginator.recalculatePaginator(
              0
            ),
          });
          return of({
            items: [],
            total: 0
          });
        }),
        finalize(() => {
          this._isLoading$.next(false);
          const itemIds = this._items$.value.map((el: T) => {
            const item = (el as unknown) as BaseModel;
            return item.id;
          });
          this.patchStateWithoutFetch({
            grouping: this._tableState$.value.grouping.clearRows(itemIds),
          });
        })
      )
      .subscribe();
    this._subscriptions.push(request);
  }

  public setDefaults() {
    this.patchStateWithoutFetch({ filter: {} });
    this.patchStateWithoutFetch({ searchTerm: '' });    
    this.patchStateWithoutFetch({ sorting: new SortState() });
    this.patchStateWithoutFetch({ grouping: new GroupingState() });    
    this.patchStateWithoutFetch({ paginator: new PaginatorState() });

    this._isFirstLoading$.next(true);
    this._isLoading$.next(true);
    this._tableState$.next(DEFAULT_STATE);
    this._errorMessage.next('');
    this._params$.next({});
  }

  // Base Methods
  public patchState(patch: Partial<any>) {
    if(patch?.hasOwnProperty('filter')){      
      let paginatorValue = { paginator: this._tableState$.value.paginator.setPage(1) }
      this.patchStateWithoutFetch(paginatorValue);
      this.resolveParams(Object.keys(paginatorValue)[0])
    }      
    this.patchStateWithoutFetch(patch);
    this.resolveParams(Object.keys(patch)[0])
    this.fetch();
  }

  public resolveParams(key) {
    switch (key) {
      case 'sorting':
        const params_sort = { sort: `${this._tableState$.value.sorting.column},${this._tableState$.value.sorting.direction}` } 
        const newParamsSort = Object.assign(this._params$.value, params_sort);
        this._params$.next(newParamsSort);
        break;
      case 'paginator':                 
        let params_paginator = { 
          page: this._tableState$.value.paginator.page - 1,
          size: this._tableState$.value.paginator.pageSize
        }
        
        const newParamsPage = Object.assign(this._params$.value, params_paginator);
        this._params$.next(newParamsPage);
        break;
      case 'filter':
        Object.keys(this._tableState$.value.filter).map((val)=>{
          if(!!!this._tableState$.value.filter[val]){
            delete this._params$.value[val];            
            delete this._tableState$.value.filter[val]
          }else{
            const params_filter: any = this._tableState$.value.filter;
            const newParamsFilter = Object.assign(this._params$.value, params_filter );
            this._params$.next(newParamsFilter);
          }
        })
        break;
    }
  }

  public patchStateWithoutFetch(patch: Partial<any>) {
    const newState = Object.assign(this._tableState$.value, patch);
    this._tableState$.next(newState);
  }
}
